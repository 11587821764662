import React from 'react';
import { RouteObject } from 'react-router-dom';
import ListApps from '../pages/apps/ListApps';
import { RedirectApp } from '../pages/apps/RedirectApp';
import { SignInPage } from '../pages/auth/SignIn';
import { SignOutPage } from '../pages/auth/SignOut';
import { ErrorPage } from '../pages/error/ErrorPage';
import { NotFoundPage } from '../pages/error/NotFoundPage';

export const routes: RouteObject[] = [
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <ListApps />,
      },
      {
        path: "/signin",
        element: <SignInPage />,
      },
      {
        path: "/signout",
        element: <SignOutPage />,
      },
      {
        path: "/app/:appName?/*",
        element: <RedirectApp />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];
