import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import log from 'loglevel';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GakakanIcon } from '../../assets/svg/GakakanIcon';
import { GoogleIcon } from '../../assets/svg/GoogleIcon';
import { BlankLayout } from '../../components/layout/BlankLayout';
import { useAppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export function SignInPage() {
  const appContext = useAppContext();
  const authContext = useAuthContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isLocal, baseUrl, appBaseUrl } = appContext.env;

  const qparams = React.useMemo(() => ({
    error: searchParams.get('error'),
    error_code: searchParams.get('error_code'),
    error_description: searchParams.get('error_description'),
    redirect: decodeURIComponent(searchParams.get('redirect') || '') || '/',
  }), [searchParams]);

  const signInWithGoogle = React.useCallback(async () => {
    if (!authContext.signInWithOAuth) {
      throw new Error('sign in not available');
    }
    const redirectTo = `${isLocal ? appBaseUrl : baseUrl}/signin`
      + `?redirect=${encodeURIComponent(qparams.redirect)}`;
    log.debug('redirect to', redirectTo);
    authContext.signInWithOAuth({
      provider: 'google',
      options: { redirectTo },
    });
  }, [qparams.redirect, authContext, isLocal, baseUrl, appBaseUrl]);

  React.useEffect(() => {
    if (!authContext.initialized) return;
    if (authContext.user) {
      navigate('/');
    }
  }, [qparams.redirect, authContext.initialized, authContext.user, navigate]);

  return (
    <BlankLayout>
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Stack direction={'column'} alignItems={'center'}>
            <GakakanIcon width='92px' />
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              Sign in
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={signInWithGoogle}
              startIcon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
            {!qparams.error_description ? <></> : (
              <Alert severity='error'>
                {qparams.error_description}
              </Alert>
            )}
          </Box>
        </Card>
      </SignInContainer>
    </BlankLayout>
  )
}
