import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { AdminLayout } from '../../components/layout/AdminLayout';

export const apps = [
  {
    name: 'quickbooks',
    title: 'Quickbooks Integration',
    description: 'Import data to your Quickbooks account',
    link: 'app/quickbooks/',
  }
];

export default function ListApps() {
  const goto = React.useCallback((link: string) => () => {
    window.location.pathname = link;
  }, []);

  const listApps = React.useMemo(() => {
    return apps.map((app) => {
      return (
        <Card variant="outlined" key={app.link}>
          <CardContent>
            <Typography variant="h5" component="div">
              {app.title}
            </Typography>
            <Typography variant="body2">

            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={goto(app.link)}>Open</Button>
          </CardActions>
        </Card>
      );
    });
  }, [goto])

  return (
    <AdminLayout>
      <Box sx={{ minWidth: 275 }}>
        {listApps}
      </Box>
    </AdminLayout>
  );
}