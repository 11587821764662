import { Box } from '@mui/material';
import React from 'react';

export type AdminLayoutProps = {
  children?: any;
}

export function BlankLayout(props: AdminLayoutProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}