import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { useRouteError } from 'react-router-dom';
import { AdminLayout } from '../../components/layout/AdminLayout';


export function ErrorPage() {
  const error: any = useRouteError();

  return (
    <AdminLayout>
      <Alert severity="error">
        <AlertTitle>😯 Something went wrong!</AlertTitle>
        {error.message || 'Sorry about that!'};
      </Alert>
    </AdminLayout>
  )
}