import * as React from 'react';
import { AdminLayout } from '../../components/layout/AdminLayout';

export function NotFoundPage() {
  return (
    <AdminLayout>
      {'Page Not Found'}
    </AdminLayout>
  )
}
