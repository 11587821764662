import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { BlankLayout } from '../../components/layout/BlankLayout';
import { useAuthContext } from '../../contexts/AuthContext';

export function SignOutPage() {
  const authContext = useAuthContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!authContext.signOut) {
      throw new Error('sign out not available')
    }
    authContext.signOut()
      .then(() => {
        navigate('/signin');
      });
  }, [authContext, navigate]);

  return (
    <BlankLayout>
      {'signing out...'}
    </BlankLayout>
  )
}
