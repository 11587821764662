import { Box } from '@mui/material';
import React from 'react';
import { SideBar } from './SideBar';
import { TopBar } from './TopBar';

export type AdminLayoutProps = {
  children?: any;
}

export function AdminLayout(props: AdminLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <>
      <TopBar openMenu={setSidebarOpen} />
      <SideBar open={sidebarOpen} setOpen={setSidebarOpen} />
      <Box sx={{ p: '20px', pb: '100px' }}>
        {props.children}
      </Box>
    </>
  )
}