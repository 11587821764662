import AccountCircle from '@mui/icons-material/AccountCircle';
import { Stack } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';

const drawerWidth = 240;

export type SideBarProps = {
  open?: boolean;
  setOpen?: (b: boolean) => void;
}

export function SideBar(props: SideBarProps) {
  const authContext = useAuthContext();
  const navigate = useNavigate();
  const { open = false, setOpen } = props;

  const signin = React.useCallback(async () => {
    navigate('/signin');
  }, [navigate]);

  const signout = React.useCallback(async () => {
    if (setOpen) {
      setOpen(false);
    }
    navigate('/signout');
  }, [navigate, setOpen]);

  const handleOnClose = React.useCallback(() => {
    if (setOpen) {
      setOpen(false);
    }
  }, [setOpen])

  return (
    <Drawer
      variant="temporary"
      sx={{
        // display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
      open={open}
      onClose={handleOnClose}
    >
      <Toolbar />
      <Stack
        direction='column-reverse'
        alignItems='stretch'
        justifyContent='flex-start'
        spacing={2}
        height={'100%'}
      >
        <List>
          {authContext.initialized && !authContext.user ? (
            <ListItem key={'login'} disablePadding>
              <ListItemButton onClick={signin}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary='Log In' />
              </ListItemButton>
            </ListItem>
          ) : <></>}
          {authContext.initialized && authContext.user ? (
            <ListItem key={'logout'} disablePadding>
              <ListItemButton onClick={signout}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary='Log Out' />
              </ListItemButton>
            </ListItem>
          ) : <></>}
        </List>
      </Stack>
    </Drawer>
  )
}
