import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

interface AppThemeProps {
  children: React.ReactNode;
}

export function AppTheme(props: AppThemeProps) {

  const theme = React.useMemo(() => {
    return createTheme({
      /**
       * For more details about CSS variables configuration
       * see https://mui.com/material-ui/customization/css-theme-variables/configuration/
       */
      cssVariables: {
        colorSchemeSelector: 'data-mui-color-scheme',
        cssVarPrefix: 'template',
      },
      /**
       * Recently added in v6 for building light & dark mode app
       * see https://mui.com/material-ui/customization/palette/#color-schemes
       */
      // colorSchemes, 
      // typography,
      // shadows,
      // shape,
      components: {
        // ...inputsCustomizations,
        // ...dataDisplayCustomizations,
        // ...feedbackCustomizations,
        // ...navigationCustomizations,
        // ...surfacesCustomizations,
        // ...themeComponents,
      },
    });
  }, []);

  return (
    <ThemeProvider theme={theme} disableTransitionOnChange>
      {props.children}
    </ThemeProvider>
  );
}
