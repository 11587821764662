import React from 'react';

export type AuthDataContext = {
  initialized: boolean;
  user?: {
    fullName: string;
    email: string;
    picture: string;
  },
  accessToken?: string;
  signInWithOAuth?: (p?: any) => any;
  signOut?: (p?: any) => any;
}

const AuthContext = React.createContext<AuthDataContext | null>(null);

export type AuthContextProviderProps = {
  children?: any;
  data: AuthDataContext
}

export function AuthContextProvider(props: AuthContextProviderProps) {
  const { data, children } = props;

  return (
    <AuthContext.Provider value={data}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuthContext must be used within AuthContextProvider');
  }
  return context;
}
