import log from 'loglevel';
import React from 'react';
import { AuthContextProvider, AuthDataContext } from '../contexts/AuthContext';
import { useSupabase } from '../hooks/useSupabase';

export function Initialize(props: { children?: any }) {
  const supabase = useSupabase();

  const data = React.useMemo<AuthDataContext>(() => {
    const user = !supabase.user ? undefined : {
      fullName: '',
      email: supabase.user?.email || '',
      picture: supabase.user?.user_metadata?.picture || '',
    };

    return {
      initialized: supabase.initialized,
      user,
      accessToken: supabase.token,
      signInWithOAuth: (p) => supabase.signInWithOAuth(p),
      signOut: () => supabase.signOut(),
    }
  }, [
    supabase,
  ]);

  React.useEffect(() => {
    if (supabase.initialized) return;
    const x = supabase.start();
    return () => x?.unsubscribe();
  }, [supabase.initialized, supabase]);

  log.debug('rerender Initialize')
  return (
    <AuthContextProvider data={data}>
      {supabase.initialized ? props.children : undefined}
    </AuthContextProvider>
  )
}
