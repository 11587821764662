import * as Sentry from "@sentry/react";

const { REACT_APP_STAGE: stage } = process.env;

Sentry.init({
  dsn: "https://bb607ba2d00122716f6e9aaa08824105@o4508061155459072.ingest.us.sentry.io/4508079659810816",
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration(),
  ],
  environment: stage,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
