import { Box } from '@mui/material';
import log from 'loglevel';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AdminLayout } from '../../components/layout/AdminLayout';
import { useAppContext } from '../../contexts/AppContext';
import { apps } from './ListApps';

export function RedirectApp() {
  const { env } = useAppContext();
  const pathParams = useParams();
  const [message, setMessage] = React.useState('Loading app...');

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const { appName } = pathParams;
    log.debug('pathname', pathname);
    log.debug('app name', appName);
    const appLink = `/app/${appName}/`;
    const app = apps.find((x) => x.name === appName);
    if (!app) {
      setMessage('App not found');
      return;
    }
    if (!pathname.startsWith(appLink)) {
      log.debug('redirect page', appLink);
      window.location.pathname = appLink;
    } else {
      log.debug('reload page', pathname);
      if (!env.isLocal) {
        window.location.reload();
      } else {
        setMessage('This page will reload only in other environments');
        return;
      }
    }
  }, [pathParams, env.isLocal]);

  return (
    <AdminLayout>
      <Box>
        {message}
      </Box>
    </AdminLayout>
  );
}
