import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export type TopBarProps = {
  openMenu?: (b: boolean) => void;
}

export function TopBar(props: TopBarProps) {
  const { openMenu } = props;

  const onClickMenu = React.useCallback(() => {
    if (openMenu) {
      openMenu(true);
    }
  }, [openMenu]);

  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ mr: 2 }}
            onClick={onClickMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Quickbooks Integration
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}
