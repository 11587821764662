import log from 'loglevel';
import * as React from 'react';
import { StageEnum } from '../react-app-env.d';

type DataContext = {
  env: {
    stage: StageEnum;
    baseUrl: string;
    appBaseUrl: string;
    apiBaseUrl: string;
    isLocal: boolean;
    isProd: boolean;
  },
};

const AppContext = React.createContext<DataContext | null>(null);
export type AppContextProviderProps = { children?: any; };

export function AppContextProvider(props: AppContextProviderProps) {
  // Environment
  const {
    REACT_APP_STAGE: stage = StageEnum.DEV,
    PUBLIC_URL: pubUrl,
    REACT_APP_API_BASE_URL: apiBaseUrl,
  } = process.env;
  const { protocol, hostname, port } = window.location;
  const baseUrl = `${protocol}//${hostname}${port ? ':' : ''}${port ? port : ''}`;
  const appBaseUrl = `${baseUrl}${pubUrl}`;
  const isLocal = stage === StageEnum.DEV;
  const isProd = stage === StageEnum.PROD;

  if (!apiBaseUrl) {
    throw new Error('REACT_APP_API_BASE_URL is not set');
  }

  const data: DataContext = {
    env: {
      stage: stage as StageEnum,
      baseUrl,
      appBaseUrl,
      apiBaseUrl,
      isLocal,
      isProd,
    },
  }

  log.debug('render AppContextProvider');
  return (
    <AppContext.Provider value={data}>
      {props.children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === null) {
    throw new Error('useAppContext must be used within AppProvider');
  }
  return context;
}
