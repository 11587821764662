import { CssBaseline } from '@mui/material';
import log, { LogLevelDesc } from 'loglevel';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Initialize } from './components/Initialize';
import { AppContextProvider } from './contexts/AppContext';
import { routes } from './routes';
import { AppTheme } from './theme/twentyfour/AppTheme';

export function App() {
  const {
    PUBLIC_URL: publicUrl = '/',
    REACT_APP_LOG_LEVEL: logLevel = 'warn',
  } = process.env;

  const router = createBrowserRouter(routes, {
    basename: publicUrl,
  });

  React.useEffect(() => {
    const level = logLevel as LogLevelDesc;
    log.setLevel(level);
    log.info(`LOGLEVEL set to '${level}'`);
    log.debug(`PUBLIC_URL: ${publicUrl}`);
  });

  return (
    <>
      <AppContextProvider>
        <Initialize>
          <AppTheme>
            <CssBaseline enableColorScheme />
            <RouterProvider router={router} />
          </AppTheme>
        </Initialize>
      </AppContextProvider>
    </>
  );
}
